import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const contact = () => (
  <Layout>
    <SEO title="Contact" />
    <div style={{paddingTop: '20px'}}>
      <h1>Find me @</h1>
    </div>
    <div className='contactInfo'>
      <div>
      	<span>Instagram: </span>
      	<a href='https://www.instagram.com/lukeohanley/'>
      	  @lukeohanley
      	</a>
      </div>
      <div style={{paddingTop: '5px'}}>
      	<span>Email: </span>
   		  <a href="mailto:laohanley@gmail.com">laohanley@gmail.com</a>
      </div>
    </div>
  </Layout>
)

export default contact;
